import { Link } from 'react-router-dom';

import { Stack, Chip } from '@mui/material';

import { styled } from '@mui/material/styles';

import { useTranslation } from 'react-i18next';

const StyledChip = styled(Chip)(({ theme }) => ({
    backgroundColor: 'white',
    '&:hover': {
        backgroundColor: theme.palette.grey[200],
    },
}));

const AboutMenu = () => {

    const { t } = useTranslation();

    return (
        <Stack direction='row' spacing={1}>
            <StyledChip
                label={t('topBar.infoAboutService')}
                to="/about-service-info"
                component={Link}
                clickable
            />
            <StyledChip
                label={t('topBar.infoOurData')}
                to="/our-data-info"
                component={Link}
                clickable
            />
            <StyledChip
                label={t('topBar.infoForCompanies')}
                to="/for-companies"
                component={Link}
                clickable
            />
            <StyledChip
                label={t('topBar.infoContact')}
                to="/contact-info"
                component={Link}
                clickable
            />
            <StyledChip
                label={t('topBar.infoPrivacyPolicy')}
                to="/privacy-policy"
                component={Link}
                clickable
            />
        </Stack>
    );
};

export default AboutMenu;
