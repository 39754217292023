import axios from 'axios';

class RegionCustomMapsService {

    constructor(regionCustomMapsApiUrl) {
        this.regionCustomMapsApiUrl = regionCustomMapsApiUrl;
    }

    async createOwner() {

        const postUrl = `${this.regionCustomMapsApiUrl}/public/custom-map/owner`;
        const { data: content } = await axios.post(postUrl);
        const newOwner = content.data;

        return newOwner;
    }

    async getOwner(ownerId) {

        try {
            const getUrl = `${this.regionCustomMapsApiUrl}/public/custom-map/owner/${ownerId}`;
            const { data: content } = await axios.get(getUrl);
            return content.data;

        } catch (error) {
            if (axios.isAxiosError(error) && error.response && error.response.status === 404) {
                return null;
            }
            
            throw error;
        }
    }

    async createMap(regionId, ownerId) {

        const postUrl = `${this.regionCustomMapsApiUrl}/public/custom-map`;
        const params = { regionId, ownerId };
        const { data: content } = await axios.post(postUrl, params);
        const newMap = content.data;

        return newMap;
    }

    async updateMap({ publicId, ownerId, showBoundaries, userLocations, serviceAmenities, leisureAmenities }) {

        try {
            const putUrl = `${this.regionCustomMapsApiUrl}/public/custom-map/${publicId}`;
            const params = { ownerId, showBoundaries, userLocations, serviceAmenities, leisureAmenities };
            const { data: content } = await axios.put(putUrl, params);
            const updatedMap = content.data;

            return updatedMap;
        } catch (error) {
            if (axios.isAxiosError(error) && error.response && error.response.status === 404) {
                return null;
            }
            
            throw error;
        }
    }

    async getMapsByOwnerId(ownerId) {

        const getUrl = `${this.regionCustomMapsApiUrl}/public/custom-map/owner/${ownerId}/custom-maps`;
        const { data: content } = await axios.get(getUrl);
        const customMaps = content.data;

        return customMaps;
    }

    async getMapById(publicId) {

        try {
            const getUrl = `${this.regionCustomMapsApiUrl}/public/custom-map/${publicId}`;
            const { data: content } = await axios.get(getUrl);
            const customMap = content.data;

            return customMap;
        } catch (error) {
            if (axios.isAxiosError(error) && error.response && error.response.status === 404) {
                return null;
            }
            
            throw error;
        }
    }

    async deleteMap(publicId, ownerId) {
        const deleteUrl = `${this.regionCustomMapsApiUrl}/public/custom-map/${publicId}`;
        const params = { ownerId };
        await axios.delete(deleteUrl, { data: params });
    }
};

export default RegionCustomMapsService;