import { configureStore, createSlice } from '@reduxjs/toolkit';

import GeoRegion from 'services/GeoRegion';

import { getSelectedCustomMap } from './utils';

const visitedRegionsSlice = createSlice({

    name: 'visitedRegions',
    initialState: { visitedRegions: [] },

    reducers: {
        addRegion: (state, action) => {

            const { visitedRegions } = state;
            const newLastRegion = action.payload;

            if (newLastRegion.id !== GeoRegion.COUNTRY_ID) {

                const currentLastRegion = visitedRegions.length && visitedRegions[visitedRegions.length - 1];

                if (!currentLastRegion || currentLastRegion.id !== newLastRegion.id) {
                    visitedRegions.push(newLastRegion);                
                }
            }
        },
        removeLastRegion: (state) => {
            state.visitedRegions = state.visitedRegions.slice(0, state.visitedRegions.length - 1);
        }
    }
});

const loadingControlSlice = createSlice({

    name: 'loadingControl',
    initialState: { loadingInProgress: true },

    reducers: {
        loadingFinished: (state) => {
            state.loadingInProgress = false;
        }
    }
});

const customMapsSlice = createSlice({

    name: 'customMaps',
    initialState: {
        customMaps: [],
        selectedCustomMapId: null,
    },

    reducers: {
        addCustomMap: (state, action) => {
            const { customMaps } = state;
            const newCustomMap = action.payload;
            customMaps.push(newCustomMap);
            state.selectedCustomMapId = newCustomMap.publicId;
        },

        removeCustomMap: (state, action) => {
            
            const { customMaps } = state;
            const publicId = action.payload;
            let deleteIdx = null;
            
            // eslint-disable-next-line no-restricted-syntax
            for (const [idx, customMap] of customMaps.entries()) {
                if (customMap.publicId === publicId) {
                    deleteIdx = idx;
                    break;
                }
            }

            if (deleteIdx !== null) {
                customMaps.splice(deleteIdx, 1);
                if (publicId === state.selectedCustomMapId) {
                    state.selectedCustomMapId = null;
                }
            }
        },

        removeAllCustomMaps: (state) => {
            state.customMaps = [];
            state.selectedCustomMapId = null;
        },

        setAllCustomMaps: (state, action) => {
            const customMaps = action.payload;
            if (customMaps.length) {
                state.customMaps = [...customMaps];
                state.selectedCustomMapId = null;    
            }
        },

        setSelectedCustomMap: (state, action) => {
            const selectedCustomMapId = action.payload;
            state.selectedCustomMapId = selectedCustomMapId;
        },

        setCurrentAsClean: (state) => {
            const selectedCustomMap = getSelectedCustomMap(state);
            selectedCustomMap.isDirty = false;
        },

        setShowBoundariesInCurrent: (state, action) => {
            const selectedCustomMap = getSelectedCustomMap(state);
            const showBoundaries = action.payload;
            selectedCustomMap.showBoundaries = showBoundaries;
            selectedCustomMap.isDirty = true;
        },

        addUserLocationInCurrent: (state, action) => {
            const selectedCustomMap = getSelectedCustomMap(state);
            const newUserLocation = action.payload;
            selectedCustomMap.userLocations.push(newUserLocation);
            selectedCustomMap.isDirty = true;
        },

        updateUserLocationInCurrent: (state, action) => {
            const selectedCustomMap = getSelectedCustomMap(state);
            const { updatedLocation, locationIdx } = action.payload;
            selectedCustomMap.userLocations[locationIdx] = updatedLocation;
            selectedCustomMap.isDirty = true;
        },

        removeUserLocationInCurrent: (state, action) => {
            const selectedCustomMap = getSelectedCustomMap(state);
            const locationIdx = action.payload;
            selectedCustomMap.userLocations.splice(locationIdx, 1);
            selectedCustomMap.isDirty = true;
        },

        addServiceAmenityInCurrent: (state, action) => {
            const selectedCustomMap = getSelectedCustomMap(state);
            const amenityId = action.payload;
            selectedCustomMap.serviceAmenities.push(amenityId);
            selectedCustomMap.isDirty = true;
        },

        removeServiceAmenityInCurrent: (state, action) => {
            const selectedCustomMap = getSelectedCustomMap(state);
            const amenityIdx = action.payload;
            selectedCustomMap.serviceAmenities.splice(amenityIdx, 1);
            selectedCustomMap.isDirty = true;
        },

        addLeisureAmenityInCurrent: (state, action) => {
            const selectedCustomMap = getSelectedCustomMap(state);
            const amenityId = action.payload;
            selectedCustomMap.leisureAmenities.push(amenityId);
            selectedCustomMap.isDirty = true;
        },

        removeLeisureAmenityInCurrent: (state, action) => {
            const selectedCustomMap = getSelectedCustomMap(state);
            const amenityIdx = action.payload;
            selectedCustomMap.leisureAmenities.splice(amenityIdx, 1);
            selectedCustomMap.isDirty = true;
        },
    }
});

const currentOwnerSlice = createSlice({

    name: 'currentOwner',
    initialState: { currentOwner: null },

    reducers: {
        setCurrentOwner: (state, action) => {

            const newOwner = action.payload;
            state.currentOwner = newOwner;
        },
    }
});

export const { addRegion, removeLastRegion } = visitedRegionsSlice.actions;
export const { loadingFinished } = loadingControlSlice.actions;
export const { setCurrentOwner } = currentOwnerSlice.actions;
export const {
    addCustomMap,
    removeCustomMap,
    removeAllCustomMaps,
    setAllCustomMaps,
    setSelectedCustomMap,
    setCurrentAsClean,
    setShowBoundariesInCurrent,
    addUserLocationInCurrent,
    updateUserLocationInCurrent,
    removeUserLocationInCurrent,
    addServiceAmenityInCurrent,
    removeServiceAmenityInCurrent,
    addLeisureAmenityInCurrent,
    removeLeisureAmenityInCurrent,
} = customMapsSlice.actions;

const store = configureStore({
    reducer: {
        visitedRegions: visitedRegionsSlice.reducer,
        loadingControl: loadingControlSlice.reducer,
        customMaps: customMapsSlice.reducer,
        currentOwner: currentOwnerSlice.reducer,
    },
});

export default store;
