import AdministrativeUnitsService from "./services/AdministrativeUnitsService";
import RegionInsightsPortalService from "./services/RegionInsightsPortalService";
import RegionDirectionsService from "./services/RegionDirectionsService";
import RegionAmenitiesService from "./services/RegionAmenitiesService";
import RegionCustomMapsService from "./services/RegionCustomMapsService";

const createServicesContext = () => {
    const administrativeUnitsService = new AdministrativeUnitsService(
        process.env.REACT_APP_ADMINISTRATIVE_UNITS_API_URL,
        process.env.REACT_APP_ADMINISTRATIVE_UNITS_COUNTRY_REF_PATH,
    );
    const regionInsightsPortalService = new RegionInsightsPortalService(
        process.env.REACT_APP_REGION_INSIGHTS_PORTAL_API_URL,
        administrativeUnitsService,
    );
    const regionDirectionsService = new RegionDirectionsService(
        process.env.REACT_APP_REGION_DIRECTIONS_API_URL,
    );
    const regionAmenitiesService = new RegionAmenitiesService(
        process.env.REACT_APP_REGION_AMENITIES_API_URL,
    );
    const regionCustomMapsService = new RegionCustomMapsService(
        process.env.REACT_APP_REGION_CUSTOM_MAPS_API_URL,
    );

    return {
        administrativeUnitsService, regionInsightsPortalService, regionDirectionsService,
        regionAmenitiesService, regionCustomMapsService,
    };
};

export default createServicesContext();